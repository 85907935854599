import React, { useEffect } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import { DEFAULT_SHOP_THEME_PAGE_IDS, SHOP_PAGE_TYPE } from 'constants/themes.constants'
import { getSellerLink } from 'utils/helpersShared.utils'
import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { CUSTOMIZATION_OPTION_KEY } from 'constants/options.constants'
import ContentPageView from 'shared/components/content-page/preview'

import { composeProps } from 'shop/utils/compose-props.utils'
import { withCommonStores } from 'shop/utils/with-common-stores.utils'
import { withLocale } from 'shop/utils/with-locale.utils'
import { withRootStore } from 'shop/utils/with-root-store.utils'
import { withScreenSize } from 'shop/utils/with-screen-size.utils'
import { withCacheControl } from 'shop/utils/cache-control.utils'
import { withGSSPLogger } from 'shop/utils/logger.utils'
import { fetchContentPageData } from 'shop/utils/fetch-content-page.utils'
import { useShopStores } from 'shop/hooks/use-store'
import { useNextRouter } from 'shop/hooks/use-next-js-router'
import { withUserSessionId } from 'shop/utils/with-user-session-id.utils'
import { withEnvVars } from 'shop/utils/withEnvVars'
import { withApiClient } from 'shop/utils/with-api-client.utils'
import { withLogger } from 'shop/utils/with-logger.utils'
import { withShopThemeStore } from 'shop/utils/with-shop-theme-store.utils'
import { withAffiliateRedirection } from 'shop/utils/with-affiliate-redirection'
import { withTranslations } from 'shop/utils/with-translations.utils'

import { AffiliateTracker } from 'shop/components/AffiliateTracker'
import { THEME_PAGE_TYPES } from 'shop/stores/shopTheme.store'
import { withExperiments } from 'shop/utils/with-experiments.utils'

type ShopMainPageParams = {
  username: string
}

interface SignInPageProps {
  screenSize: string
  contentPageData: any // TODO: SSR - rewrite fetchContentPage and screen_size in isomorphic way with MOBX store without Component state
}

const ShopMainPage: React.FC<SignInPageProps> = observer(function ShopMainPage({ contentPageData, screenSize }) {
  const {
    contentPageStore,
    countriesStore,
    sellerStore,
    shopThemeStore,
    trackingCodesStore,
    trackingUserEventsStore,
    userStore,
  } = useShopStores()
  const { LinkComponent, historyPush, pathname, params } = useNextRouter<ShopMainPageParams>()

  useEffect(() => {
    trackingCodesStore.setTrackEventData('shop_page', {
      sellerId: sellerStore.item.id,
      username: sellerStore.item.username,
      consentForm: sellerStore.item.consentForm,
    })
    trackingCodesStore.trackEvent()

    trackingUserEventsStore.track({
      pageType: 'shop',
      eventType: 'page_view',
      withExperiment: true,
    })

    trackingUserEventsStore.trackLegacy({
      actionType: 'shop',
      sellerId: sellerStore.item.id,
    })

    return () => {
      trackingCodesStore.setTrackEventData()
    }
  }, [])

  const { loading } = contentPageStore
  const headerHidden = shopThemeStore.displayData.headerGeneral
    ? shopThemeStore.shopTheme.prefs.headerHidden
    : shopThemeStore.displayData.headerHidden
  const pageType = SHOP_PAGE_TYPE.main
  const currentThemePage = shopThemeStore.shopPages.find((tp) => tp.form === 'main')
  const contentPageId =
    sellerStore.isAppActive(CUSTOMIZATION_OPTION_KEY) && currentThemePage
      ? (currentThemePage || {}).contentPageId
      : DEFAULT_SHOP_THEME_PAGE_IDS.main

  return (
    <AffiliateTracker params={params}>
      {contentPageId && (
        <ContentPageView
          id={contentPageId}
          scope={`shop/${params.username}`}
          headerHidden={headerHidden}
          username={params.username}
          baseURL={getSellerLink(sellerStore.item)}
          countries={countriesStore.list}
          themeData={shopThemeStore.shopTheme}
          pageType={pageType}
          contentPageData={contentPageData}
          screenSize={screenSize}
          LinkComponent={LinkComponent}
          historyPush={historyPush}
          pathname={pathname}
          queryParams={params}
          urlParams={params}
          contentPageStore={contentPageStore}
          userStore={userStore}
        />
      )}
      {loading && <LoadingMask />}
    </AffiliateTracker>
  )
})

export default ShopMainPage

const getPageProps = async (context) => {
  const screenSize = context.screenSize
  const { username } = context.params
  const mainContentThemePage = context.rootStore.shopThemeStore.shopPages.find((shopPage) => shopPage.form === 'main')
  const contentPageId = context.rootStore.sellerStore.isAppActive(CUSTOMIZATION_OPTION_KEY)
    ? mainContentThemePage?.contentPageId
    : DEFAULT_SHOP_THEME_PAGE_IDS.main

  const { contentPageData, contentPageStore, affiliateProgramsStore, notFound } = await fetchContentPageData(
    {
      username,
      contentPageId,
      screenSize,
      pageType: SHOP_PAGE_TYPE.main,
      groupId: context.query.group_id,
    },
    context
  )

  if (notFound) {
    return {
      notFound: true,
      props: {},
    }
  }

  return {
    props: {
      initialData: {
        contentPageStore,
        affiliateProgramsStore,
        productGroupsStore: {
          list: toJS(context.rootStore.productGroupsStore.list),
          loading: toJS(context.rootStore.productGroupsStore.loading),
        },
        shopThemeStore: {
          shopTheme: toJS(context.rootStore.shopThemeStore.shopTheme),
          shopPages: toJS(context.rootStore.shopThemeStore.shopPages),
          pageType: THEME_PAGE_TYPES.main,
        },
      },
      contentPageData,
      metaData: { contentPage: mainContentThemePage },
    },
  }
}

export const getServerSideProps = withGSSPLogger(
  composeProps(
    withLocale,
    withTranslations,
    withApiClient,
    withEnvVars,
    withUserSessionId,
    withRootStore,
    withScreenSize,
    withAffiliateRedirection,
    withCommonStores,
    withShopThemeStore,
    getPageProps,
    withExperiments,
    withCacheControl(60),
    withLogger
  ),
  'getServerSideProps ShopMainPage'
)
