import React, { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'

import { useShopStores } from 'shop/hooks/use-store'

interface AffiliateTrackerParams {
  username: string
  slug?: string
  product_slug?: string
}

export const AffiliateTracker = observer(({ children, params }: { children: any; params?: AffiliateTrackerParams }) => {
  const { username, slug, product_slug } = params
  const { affiliatesStore } = useShopStores()

  const trackAffiliate = () => {
    affiliatesStore.checkAffiliateTracking({ username, slug, product_slug })
  }

  useEffect(() => {
    trackAffiliate()
  }, [])

  return <Fragment>{children}</Fragment>
})
